// src/components/Home.js
import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';

const Home = (context) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <Box textAlign="center" mt={10}>
      <Heading>Welcome to IndexerGlue!</Heading>
      <Text fontSize="xl" mt={4}>
        A simple interface to manage your API keys.
      </Text>
      {!isAuthenticated && (
        <Button
          colorScheme="teal"
          size="lg"
          mt={6}
          onClick={() =>
            loginWithRedirect({
              redirectUri: `${window.location.origin}/dashboard`,
            })
          }>
          Get Started
        </Button>
      )}
    </Box>
  );
};

export default Home;
