// src/components/Dashboard.js

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Heading,
  List,
  ListItem,
  Button,
  Input,
  Flex,
  Box,
  Stack,
  Text,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';


const AccountInfo = () => {
    // State variables
    const [userApiKey, setUserApiKey] = useState(''); // New state variable
    const [loading, setLoading] = useState(true); // To show a loading spinner
    const [error, setError] = useState(null); // To handle errors
  
    // Auth0 hook to get access token
    const { getAccessTokenSilently } = useAuth0();
    const { user } = useAuth0();
    const navigate = useNavigate();
    // Function to fetch API keys from the backend

  const fetchUserApiKey = async (context) => {
    try {
      setLoading(true);
      setError(null);
      const domain = context.env.AUTH0_DOMAIN;
      const clientId = context.env.CLIENT_ID;
      const audience = context.env.AUDIENCE;
  
      const token = await getAccessTokenSilently({
        detailedResponse: true,
        audience: audience,
        clientId: clientId,
        domain: domain,
        scope: "read:all write:all",
        onRedirectCallback: (appState) => {
          navigate(appState?.returnTo || window.location.pathname);
        },
        redirectUri: `${window.location.origin}/dashboard`,
      });
        console.log('Access token retrieved:', token);

      const response = await axios.get('https://api.indexerglue.com/get_api_key', 
        {userName: userApiKey},{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('API response:', response.data);
      setUserApiKey(response.data[0].api_key);
    } catch (error) {
      console.error('Error fetching user API key:', error);
      setError('Failed to load user API key.');
    } finally {
      setLoading(false);
    }
  };
  
    useEffect(() => {
        fetchUserApiKey();
    }, []);

  return (
    <Box mt={8}>
      <Heading mb={4}>Your Auth Info</Heading>
        <Text>{user.email}</Text>
        <Heading mb={4}>Your User API Key</Heading>
        <Text>{userApiKey}</Text>
    </Box>
    );
};

export default AccountInfo;
