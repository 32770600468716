// src/index.js or src/auth0-provider-with-history.js

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const audience = process.env.REACT_APP_AUDIENCE;

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}/dashboard`}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
      scope="read:all write:all"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
