// src/components/Dashboard.js

import React, { useEffect, useState, } from 'react';
import axios from 'axios';
import {
  Heading,
  List,
  ListItem,
  Button,
  Input,
  Flex,
  Box,
  Stack,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Select,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';

const Dashboard = () => {
  // State variables
  const [apiKeys, setApiKeys] = useState([]);
  const [newApiKey, setNewApiKey] = useState('');
  const [userApiKey, setUserApiKey] = useState('');
  const [indexerName, setIndexerName] = useState('');
  const [newIndexerUrl, setNewIndexerUrl] = useState('');
  const [newIndexerApiParam, setNewIndexerApiParam] = useState('');
  const [newIndexerName, setNewIndexerName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [indexerList, setIndexerList] = useState([]);

  // Auth0 hook to get access token
  const { getAccessTokenSilently } = useAuth0();

  const audience = process.env.REACT_APP_AUDIENCE;

  // Function to fetch API keys from the backend
  const fetchApiKeys = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:all write:all',
      });

      const response = await axios.get('/api/list_user_indexers', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setApiKeys(response.data);
    } catch (error) {
      console.error('Error fetching API keys:', error);
      setError('Failed to load API keys.');
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch user API key from the backend
  const fetchUserApiKey = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:all write:all',
      });

      const response = await axios.get('/api/get_api_key', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUserApiKey(response.data.api_key);
    } catch (error) {
      console.error('Error fetching user API key:', error);
      setError('Failed to load user API key.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch API keys when the component mounts
  useEffect(() => {
    fetchApiKeys();
    fetchUserApiKey();
    makeIndexerList();
  }, []);

  // Function to add an API key
  const addApiKey = async () => {
    if (!newApiKey.trim()) {
      setError('API key cannot be empty.');
      return;
    }
    if (!indexerName.trim()) {
      setError('Indexer name cannot be empty.');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:all write:all',
      });

      await axios.post(
        '/api/update_user_indexers',
        {
          user_api_key: userApiKey,
          api_key: newApiKey,
          indexer_name: indexerName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchApiKeys();
    } catch (error) {
      console.error('Error adding API key:', error);
      setError('Failed to add API key.');
    } finally {
      setLoading(false);
    }
  };

  // Function to add a new indexer
  const addNewIndexer = async () => {
    if (!newIndexerName.trim()) {
      setError('Indexer name cannot be empty.');
      return;
    }
    if (!newIndexerUrl.trim()) {
      setError('Indexer URL cannot be empty.');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:all write:all',
      });

      await axios.post(
        '/api/add_indexer',
        {
          name: newIndexerName,
          url: newIndexerUrl,
          api_param: newIndexerApiParam || 'apikey',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchApiKeys();
    } catch (error) {
      console.error('Error adding new indexer:', error);
      setError('Failed to add new indexer.');
    } finally {
      setLoading(false);
    }
  };

  // Function to delete an API key
  const deleteApiKey = async (id) => {
    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:all write:all',
      });

      await axios.post(
        '/api/delete-indexer-api-key',
        {
          indexer_name: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchApiKeys();
    } catch (error) {
      console.error('Error deleting API key:', error);
      setError('Failed to delete API key.');
    } finally {
      setLoading(false);
    }
  };

  // Function to generate a new user API key
  const generateUserApiKey = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:all write:all',
      });

      const response = await axios.post(
        '/api/generate_api_key',
        {}, // Empty data payload
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const newApiKey = response.data.api_key;
      setUserApiKey(newApiKey);
    } catch (error) {
      console.error('Error generating API key:', error);
      setError('Failed to generate API key.');
    } finally {
      setLoading(false);
    }
  };
  const manualIndexerList = List({
    items: [
      { label: "DogNZB", value: "dognzb" },
      { label: "Drunkenslug", value: "drunkenslug" },
      { label: "ABNzb", value: "abnzb" },
      { label: "Althub", value: "althub" },
      { label: "NZB Finder", value: "nzbfinder" },
      { label: "NZBGeek", value: "nzbgeek" },
      { label: "NZBPlanet", value: "nzbplanet" },
      { label: "NZB.su", value: "nzbsu" },
      { label: "UseNet-Crawler", value: "usenetcrawler" },
      { label: "Digitalcarnage", value: "digitalcarnage" },
      { lable: "Scenenzb", value: "scenenzb" },
    ],
  });

  const makeIndexerList = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:all write:all',
      });

      const response = await axios.get('/api/get_indexers', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        const kvResultList = List({
          items: response.data,
        });
        setIndexerList(kvResultList);
      } else {
        setIndexerList(manualIndexerList);
      }
    } catch (error) {
      console.error('Error fetching indexers from KV', error);
      setError('Failed to load indexers.');
      setIndexerList(manualIndexerList);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box mt={8}>
      <Heading mb={4}>Your User API Key</Heading>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <>
          {userApiKey ? (
            <Text>{userApiKey}</Text>
          ) : (
            <Text>No user API keys found. Generate a new user API key below.</Text>
          )}
        </>
      )}
      <Box mt={6}>
        <Button colorScheme="teal" onClick={generateUserApiKey}>
          Generate New User API Key
        </Button>
      </Box>

      <Heading mb={4}>Your Indexer API Keys</Heading>

      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}

      {loading ? (
        <Spinner size="xl" />
      ) : (
        <>
          {Array.isArray(apiKeys) && apiKeys.length > 0 ? (
            <List spacing={3}>
              {apiKeys.map((key) => (
                <ListItem key={key.id}>
                  <Flex align="center" justify="space-between">
                    <Box>{key.api_key}</Box>
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => deleteApiKey(key.id)}
                    >
                      Delete
                    </Button>
                  </Flex>
                </ListItem>
              ))}
            </List>
          ) : (
            <Text>No Indexer API keys found</Text>
          )}

          <Heading mb={4}>Add a new indexer api key</Heading>
          <Stack mt={6} direction="row">
            <Input
              label="API Key"
              helperText="Your personal API key for your indexer"
              value={newApiKey}
              onChange={(e) => setNewApiKey(e.target.value)}
            />
            <Select 
            placeholder = "GeekNZB"
            value={indexerName}
            onChange={(e) => setIndexerName(e.target.value)}
            >
              {indexerList.items.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select> 
            <Button colorScheme="teal" onClick={addApiKey}>
              Add API Key
            </Button>
          </Stack>

          <Heading mb={4}>Add a new indexer</Heading>
          <Stack mt={6} direction="row">
            <Input
              label="Indexer Name"
              helperText="The name of your indexer"
              value={newIndexerName}
              onChange={(e) => setNewIndexerName(e.target.value)}
            />
            <Input
              label="Indexer URL"
              helperText="The URL of your indexer"
              placeholder="https://yourindexer.com/api"
              value={newIndexerUrl}
              onChange={(e) => setNewIndexerUrl(e.target.value)}
            />
            <Input
              label="Indexer API Parameter"
              helperText="The API parameter used by the indexer"
              placeholder="apikey"
              value={newIndexerApiParam}
              onChange={(e) => setNewIndexerApiParam(e.target.value)}
            />
            <Button colorScheme="teal" onClick={addNewIndexer}>
              Add New Indexer
            </Button>
          </Stack>  
        </>
      )}
    </Box>
  );
};

export default Dashboard;
